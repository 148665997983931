import React from 'react';
// do not use this example everywhere, no theming support in initial loading
// todo: add theming support for all other loaders
import injectSheetRaw from 'react-jss';
import {combineStyle, injectSheet, withTheme} from "../lib/theme";

const styles = {
    '@keyframes loading': {
        '0%': {
            top: '-1px',
            left: '-1px',
            width: '50px',
            height: '50px',
            opacity: 0,
        },
        '3%': {
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            top: '23px',
            left: '23px',
            width: '2px',
            height: '2px',
            opacity: 0,
        }
    },
    wrapper: {
        display: 'block',
        position: 'relative',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '48px',
        height: '48px',
        overflow: 'hidden'
    },
    item: {
        position: 'absolute',
        border: '2px solid #fff',
        opacity: '0',
        borderRadius: '50%',
        animation: 'loading 2.4s cubic-bezier(0, 0.3, 0.8, 1) infinite',
        '&:nth-child(2)': {
            animationDelay: '-1.2s',
        },
        '&:nth-child(3)': {
            animationDelay: '-0.8s',
        }
    }
};

class Loading extends React.Component {
    render() {
        // error is e.g. given from `Loadable` and is considered fatal error
        // errorWarning is is considered simple error
        const {classes, error, errorWarning, theme, absolute, name} = this.props;

        let styled = {wrapper: {}, item: {}};
        if(theme) {
            // Loading can be loaded withTheme or just plain (e.g. when loading BaseApp where ThemeProvider is first set)
            styled.item.borderColor = theme.textColor;
        }

        if(absolute) {
            styled.wrapper.position = 'absolute';
            styled.wrapper.left = '50%';
            styled.wrapper.transform = 'translateX(-50%)';
        }

        let style = {};
        if(this.props.style) {
            style.wrapper = this.props.style.wrapper || {};
            style.item = this.props.style.item || {};
        } else {
            style.wrapper = {};
            style.item = {};
        }

        if(errorWarning) {
            style.item.borderColor = theme.errorColor;
        }

        styled.wrapper = combineStyle(styled.wrapper, style.wrapper);
        styled.item = combineStyle(styled.item, style.item);

        return (
            error ? (
                    <React.Fragment>
                        {this.props.children}
                        <p>Fatal Error on Loading Component {name}</p>
                        <p>{JSON.stringify(error)}</p>
                    </React.Fragment>
                ) :
                <React.Fragment>
                    {this.props.children}
                    <div className={classes.wrapper} style={styled.wrapper}>
                        <span className={classes.item} style={styled.item}/>
                        <span className={classes.item} style={styled.item}/>
                        <span className={classes.item} style={styled.item}/>
                    </div>
                </React.Fragment>
        )
    };
}

const LoadingRaw = injectSheetRaw(styles)(Loading);

export default injectSheet(styles)(withTheme(Loading));

export {LoadingRaw};